import { createAction, props } from '@ngrx/store';
import { ClubDetails } from '../../../models/club-details.interface';

const IdProp = props<{ id: number }>();

export const loadClub = createAction(
  '[Club Edit] Load club',
  props<{ id: number; status: string }>(),
);

export const backClicked = createAction('[Club Edit] backClicked');
export const stepBackClicked = createAction('[Club Edit] Step Back Clicked');
export const clubInformationNextClicked = createAction(
  '[Club Edit] Club Information Next Clicked',
);
export const contactDataNextClicked = createAction(
  '[Club Edit] Contact Data Next Clicked',
);
export const socialsNextClicked = createAction(
  '[Club Edit] Socials Next Clicked',
);
export const clubInformationValid = createAction(
  '[Club Edit] Club Information Valid',
);
export const clubInformationInvalid = createAction(
  '[Club Edit] Club Information Invalid',
);
export const socialsValid = createAction('[Club Edit] Socials Valid');
export const socialsInvalid = createAction('[Club Edit] Socials Invalid');
export const contactDataValid = createAction('[Club Edit] Contact Data Valid');
export const contactDataInvalid = createAction(
  '[Club Edit] Contact Data Invalid',
);

export const selectTab = createAction(
  '[Club Edit] Select Tab',
  props<{ currentStep: string }>(),
);

export const loadClubSuccess = createAction(
  '[Club Edit] Load club success',
  props<{ data: ClubDetails }>(),
);

export const saveClub = createAction(
  '[Club Edit] Save club',
  props<{ id: number; status: string }>(),
);

export const createClub = createAction('[Club Edit] Create club');

export const uploadLogo = createAction('[Club Edit] Upload logo', IdProp);

export const addPackage = createAction(
  '[Club Edit] Open package create page',
  props<{ clubId: number; locationId: number }>(),
);

export const editPackage = createAction(
  '[Club Edit] Open package edit page',
  props<{ clubId: number; locationId: number; packageId: number }>(),
);

export const deletePackage = createAction(
  '[Club Edit] Delete package',
  props<{ packageId: number }>(),
);

export const deletePackageSuccess = createAction(
  '[Club Edit] Delete package success',
  props<{ packageId: number }>(),
);

export const addLounge = createAction(
  '[Club Edit] Open lounge create page',
  props<{ locationId: number; clubId: number }>(),
);

export const editLounge = createAction(
  '[Club Edit] Open lounge edit page',
  props<{ clubId: number; loungeId: number }>(),
);

export const deleteLounge = createAction(
  '[Club Edit] Delete lounge',
  props<{ loungeId: number }>(),
);

export const deleteLoungeSuccess = createAction(
  '[Club Edit] Delete lounge success',
  props<{ loungeId: number }>(),
);

export const deleteLoungeError = createAction(
  '[Club Edit] Delete lounge error',
  props<{ loungeId: number }>(),
);
